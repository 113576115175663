/* html, body {
  height: 100%;

  display: flex;
  flex-direction: column;
} */

html{
  /*background-color: #282c34;*/
  max-height: 100%;
  margin: 0;
}

body{
  font-size: calc(10px + 1.5vmin);

  text-align: center;
/* 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; */
  margin: auto;
  padding: 0;


}

h1 {
  text-align: center;
  margin: 0;
}

/* .vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
} */

.App {
  /* max-width: 900px; */
  margin: 0;
}

.obi-login form {
  display: block;
  margin: auto;
  justify-content: center;
}

button:hover{
  background-color: silver;
}


.App-header {
}

.App-body {
 
  font-size: calc(10px + 1.5vmin);
  color: white;
  width: 100%;
  margin: auto;

  padding-top: 10px;
  
  max-width: 1000px;

  text-align: center;
  align-content: center;


}

.active {
  color:red;
}


.App-link {
  color: #61dafb;
}

.progress-container {
  /*overflow: scroll;*/
}

.record-table{
  /*height: 90%;*/
}

.manage-course-row {
  display: flex;
  flex-direction: row;

  width: 90%;

  justify-content: center;


}

.manage-course-name{
  width: 800px;

  text-align: left;
}

.manage-progress{
  width: 100px;
}

.rows {

}

.manage-course{
  overflow: scroll;
}

.manage-course-rows{
  margin: auto;
  width: 90%;

  
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 10px;
  background-color: black; */


}


.courseRow{
  margin: auto;

  width: 90%;

  justify-content: center;

  display: flex;
  flex-direction: row;


}

.result-selector{
}

.result-chart-container{
  position: relative;
  height: 60vh;
  width: 80vh;

}

.courseName{
  width: 80%;
  text-align: left;

}

.render-session-table{
  /* height: 300px; */
  width: 90vw;

}

.courseProgress{
  width: 100px;
  text-align: left;

}

.courseResumeButton{
  text-align: left;
  margin-right: 10px;
}

.results {
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;

  align-content: center;
}

.next-button{
  display:block;
  margin: auto;
  margin-top: 10px;
}

.DataTable {
  background-color: olivedrab;
  width: '900px';
  height: "100%" ;
}